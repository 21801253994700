<template>
  <div class="bg-white rounded h-full p-14 md:p-28 mb-60 md:mb-28">
    <div class="max-w-320">
      <div class="flex items-center mb-28">
        <AppUserAvatar
          :username="originalDetails.firstName + ' ' + originalDetails.lastName"
          :loading="vuex.isLoading"
          class="min-h-100 min-w-100 text-3xl mr-10"
        />
        <AppButton
          v-if="false"
          type="primary"
          class="px-40 py-10"
          disabled
        >
          Upload Avatar
        </AppButton>
      </div>

      <form
        data-test="login-form"
        @submit.prevent="onSubmit"
      >
        <AppInput
          v-model="v$.firstName.$model"
          name="firstName"
          maxlength="100"
          label="First Name"
          class="mb-18 w-full"
          :error="errorMessages.firstName"
          @focus="clearErrorField('firstName')"
        />
        <AppInput
          v-model="v$.lastName.$model"
          name="lastName"
          maxlength="100"
          label="Last Name"
          class="mb-18 w-full"
          :error="errorMessages.lastName"
          @focus="clearErrorField('lastName')"
        />
        <AppInput
          v-model="v$.zipCode.$model"
          name="zipCode"
          class="mb-18 w-full"
          mask="#####"
          placeholder="00000"
          :error="errorMessages.zipCode"
          @focus="clearErrorField('zipCode')"
          @blur="validateField('zipCode')"
        >
          <template #label>
            <p class="text-dark-cl-20">ZIP CODE <span class="text-grey-fp-60">(optional)</span></p>
          </template>
        </AppInput>

        <AppInput
          v-model="v$.phoneNumber.$model"
          name="phoneNumber"
          label="Mobile Phone Number"
          mask="+1 (###) ###-####"
          placeholder="+1 (777) 777-7777"
          class="mb-18 w-full"
          :error="errorMessages.phoneNumber"
          @focus="clearErrorField('phoneNumber')"
          @blur="validateField('phoneNumber')"
        />

        <AppInput
          v-model="v$.secondaryPhoneNumber.$model"
          name="secondaryPhoneNumber"
          label="Secondary Phone Number"
          mask="+1 (###) ###-####"
          placeholder="+1 (777) 777-7777"
          class="w-full mb-14"
          :error="errorMessages.secondaryPhoneNumber"
          @focus="clearErrorField('secondaryPhoneNumber')"
          @blur="validateField('secondaryPhoneNumber')"
        >
          <template #label>
            <p class="text-dark-cl-20">Secondary Phone Number <span class="text-grey-fp-60">(optional)</span></p>
          </template>
        </AppInput>
      </form>
    </div>

    <!-- A C T I O N S  M O B I L E -->
    <div
      ref="stickyActions"
      class="mobile-actions"
    >
      <AppButton
        type="primary"
        custom-class="px-30 py-10 w-full mr-5"
        :disabled="disableAction || v$.$invalid"
        @click="onSubmit"
      >
        Save changes
      </AppButton>

      <AppButton
        type="secondary"
        custom-class="px-30 py-10 w-full ml-5"
        @click="onCancel"
      >
        Cancel
      </AppButton>
    </div>
  </div>

  <!-- D E S K T O P  A C T I O N S -->
  <div class="hidden sm:flex items-center justify-center mt-40">
    <AppButton
      type="primary"
      custom-class="px-40 py-10 mr-5"
      :disabled="disableAction || v$.$invalid"
      @click="onSubmit"
    >
      Save changes
    </AppButton>

    <AppButton
      type="secondary"
      custom-class="px-40 py-10 ml-5"
      @click="onCancel"
    >
      Cancel
    </AppButton>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, PropType, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { required, helpers } from "@vuelidate/validators";
  import { isEqual } from 'lodash';

  import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, IProfile } from '@/types';
  import { userDetailsVliadationRules } from '../validation-rules';
  import { clearErrorField, handleSetErrors, validateField, formatPhoneNumber } from '@/core/helper-functions';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'UserDetails',

    components: { AppUserAvatar, AppButton, AppInput },

    props: {
      details: {
        type: Object as PropType<IProfile>,
        required: true
      },
      originalDetails: {
        type: Object as PropType<IProfile>,
        required: true
      }
    },

    emits: ['submit', 'cancel'],

    setup(props, { emit }) {

      const errorMessages = reactive<IErrorFields>({
        firstName: '',
        lastName: '',
        zipCode: '',
        phoneNumber: '',
        secondaryPhoneNumber: ''
      });

      const { details, originalDetails } = toRefs(props);

      // Validation rules, added required to phone number if its already exits, not able to remove it in that case[Problem with AWS Cognito]
      const rules = computed(() => (
        {
          ...userDetailsVliadationRules,
          phoneNumber: originalDetails.value.phoneNumber
            ? {
              ...userDetailsVliadationRules.phoneNumber,
              required: helpers.withMessage('This field cannot be empty', required)
            }
            : userDetailsVliadationRules.phoneNumber
        }
      ));

      const disableAction = computed<boolean>(() => {
        return isEqual(
          originalDetails.value, 
          {
            ...details.value,
            phoneNumber: details.value.phoneNumber
              ? formatPhoneNumber(details.value.phoneNumber)
              : "",
            secondaryPhoneNumber: details.value.secondaryPhoneNumber
              ? formatPhoneNumber(details.value.secondaryPhoneNumber)
              : "",
          }
        );
      });

      const v$ = useVuelidate(rules, details);

      async function onSubmit() {
        if (await v$.value.$validate()) { emit('submit', {
            ...details.value,
            phoneNumber: details.value.phoneNumber
              ? formatPhoneNumber(details.value.phoneNumber)
              : "",
            secondaryPhoneNumber: details.value.secondaryPhoneNumber
              ? formatPhoneNumber(details.value.secondaryPhoneNumber)
              : "",
            zipCode: details.value.zipCode ? details.value.zipCode : ""
          }); }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      function onCancel() { emit('cancel'); }

      return {
        errorMessages,
        v$,
        disableAction,
        vuex,

        onCancel,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }
  });
</script>

<style lang="scss" scoped>
  .mobile-actions {
    @apply fixed bottom-0 right-22 left-22 
      flex sm:hidden items-center justify-center 
      bg-white z-5 py-14 px-14 rounded-t-5;

    box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.05);
  }
</style>