
  import { defineComponent, reactive, toRefs, PropType, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { required, helpers } from "@vuelidate/validators";
  import { isEqual } from 'lodash';

  import AppUserAvatar from '@/components/stateless/AppUserAvatar.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, IProfile } from '@/types';
  import { userDetailsVliadationRules } from '../validation-rules';
  import { clearErrorField, handleSetErrors, validateField, formatPhoneNumber } from '@/core/helper-functions';
  import { vuex } from '@/store';

  export default defineComponent({
    name: 'UserDetails',

    components: { AppUserAvatar, AppButton, AppInput },

    props: {
      details: {
        type: Object as PropType<IProfile>,
        required: true
      },
      originalDetails: {
        type: Object as PropType<IProfile>,
        required: true
      }
    },

    emits: ['submit', 'cancel'],

    setup(props, { emit }) {

      const errorMessages = reactive<IErrorFields>({
        firstName: '',
        lastName: '',
        zipCode: '',
        phoneNumber: '',
        secondaryPhoneNumber: ''
      });

      const { details, originalDetails } = toRefs(props);

      // Validation rules, added required to phone number if its already exits, not able to remove it in that case[Problem with AWS Cognito]
      const rules = computed(() => (
        {
          ...userDetailsVliadationRules,
          phoneNumber: originalDetails.value.phoneNumber
            ? {
              ...userDetailsVliadationRules.phoneNumber,
              required: helpers.withMessage('This field cannot be empty', required)
            }
            : userDetailsVliadationRules.phoneNumber
        }
      ));

      const disableAction = computed<boolean>(() => {
        return isEqual(
          originalDetails.value, 
          {
            ...details.value,
            phoneNumber: details.value.phoneNumber
              ? formatPhoneNumber(details.value.phoneNumber)
              : "",
            secondaryPhoneNumber: details.value.secondaryPhoneNumber
              ? formatPhoneNumber(details.value.secondaryPhoneNumber)
              : "",
          }
        );
      });

      const v$ = useVuelidate(rules, details);

      async function onSubmit() {
        if (await v$.value.$validate()) { emit('submit', {
            ...details.value,
            phoneNumber: details.value.phoneNumber
              ? formatPhoneNumber(details.value.phoneNumber)
              : "",
            secondaryPhoneNumber: details.value.secondaryPhoneNumber
              ? formatPhoneNumber(details.value.secondaryPhoneNumber)
              : "",
            zipCode: details.value.zipCode ? details.value.zipCode : ""
          }); }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      function onCancel() { emit('cancel'); }

      return {
        errorMessages,
        v$,
        disableAction,
        vuex,

        onCancel,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }
  });
