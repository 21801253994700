<template>
  <div class="bg-white rounded h-full px-14 pt-14 pb-28 mb-50 md:mb-28">
    <div class="max-w-320">
      <form
        @submit.prevent="onSubmit"
      >
        <AppInput
          v-model="v$.currentPassword.$model"
          name="currentPassword"
          label="Current Password"
          type="password"
          class="mb-25"
          :error="errorMessages.currentPassword"
          @focus="clearErrorField('currentPassword')"
          @blur="validateField('currentPassword')"
        />
        <AppInput
          v-model="v$.newPassword.$model"
          name="newPassword"
          label="New Password"
          type="password"
          class="mb-25"
          use-hint
          :hintOptions="hintOptions"
          :error="errorMessages.newPassword"
          @focus="clearErrorField('newPassword')"
          @blur="validateField('newPassword')"
        />

        <AppInput
          v-model="v$.confirmPassword.$model"
          name="confirmPassword"
          label="Confirm New Password"
          type="password"
          :error="errorMessages.confirmPassword"
          @focus="clearErrorField('confirmPassword')"
          @blur="validateField('confirmPassword')"
        />
      </form>
    </div>

    <!-- A C T I O N S  M O B I L E -->
    <div
      ref="stickyActions"
      class="mobile-actions"
    >
      <AppButton
        type="primary"
        custom-class="px-30 py-10 w-full mr-5"
        @click="onSubmit"
      >
        Save changes
      </AppButton>

      <AppButton
        type="secondary"
        custom-class="px-30 py-10 w-full ml-5"
        @click="onCancel"
      >
        Cancel
      </AppButton>
    </div>
  </div>

  <!-- D E S K T O P  A C T I O N S -->
  <div class="hidden sm:flex items-center justify-center mt-40">
    <AppButton
      type="primary"
      custom-class="px-40 py-10 mr-5"
      @click="onSubmit"
    >
      Save changes
    </AppButton>

    <AppButton
      type="secondary"
      custom-class="px-40 py-10 ml-5"
      @click="onCancel"
    >
      Cancel
    </AppButton>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, PropType, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from '@vuelidate/validators';
  import { useToast } from "vue-toastification";
  import { useRouter } from 'vue-router';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, IPasswords } from '@/types';
  import { passwordValidation } from '../validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'UserDetails',

    components: { AppButton, AppInput },

    props: {
      passwords: {
        type: Object as PropType<IPasswords>,
        required: true
      }
    },

    emits: ['cancel', 'clear-password-fields'],

    setup(props, { emit }) {
      const toast = useToast();
      const router = useRouter();

      const state = reactive({
        errorMessages: {
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        } as IErrorFields
      });

      const { passwords } = toRefs(props);
      const { errorMessages } = toRefs(state);

      const rules = computed(() => {
        return {
          currentPassword: { ...passwordValidation },
          newPassword: { ...passwordValidation },
          confirmPassword: {
            ...passwordValidation,
            sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(passwords.value.newPassword))
          }
        };
      });

      const v$ = useVuelidate(rules, passwords);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.newPassword.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.newPassword.minLength.$invalid && v$.value.newPassword.$model },
        { message: 'At least one Number', matched: !v$.value.newPassword.containsNumber.$invalid }
      ]);

      function onCancel() {
        emit('cancel');
      }

      async function changePassword() {
        await authService.changePassword(passwords.value.currentPassword, passwords.value.newPassword)
          .then(() => {
            toast.success('Password has beed updated');
            emit('clear-password-fields');
            router.replace({ name: routesNames.userProfile });
          })
          .catch(({ code, message }) => {
            if (code === 'NotAuthorizedException') {
              errorMessages.value.currentPassword = 'Incorrect password';
            } else {
              toast.error(message);
            }
          });
      }

      async function onSubmit() {
        if (await v$.value.$validate()) { changePassword(); }
        else { handleSetErrors(v$.value.$errors, errorMessages.value); }
      }

      return {
        errorMessages,
        v$,
        hintOptions,

        onCancel,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages.value),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages.value),
      };
    }
  });
</script>

<style lang="scss" scoped>
  .mobile-actions {
    @apply fixed bottom-0 right-22 left-22 
      flex sm:hidden items-center justify-center 
      bg-white z-5 py-14 px-14 rounded-t-5;

    box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.05);
  }
</style>