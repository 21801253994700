
  import { defineComponent, reactive, toRefs, PropType, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { sameAs, helpers } from '@vuelidate/validators';
  import { useToast } from "vue-toastification";
  import { useRouter } from 'vue-router';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { IErrorFields, IPasswords } from '@/types';
  import { passwordValidation } from '../validation-rules';
  import { clearErrorField, handleSetErrors, validateField } from '@/core/helper-functions';
  import { authService } from '@/services';
  import { routesNames } from '@/router';

  export default defineComponent({
    name: 'UserDetails',

    components: { AppButton, AppInput },

    props: {
      passwords: {
        type: Object as PropType<IPasswords>,
        required: true
      }
    },

    emits: ['cancel', 'clear-password-fields'],

    setup(props, { emit }) {
      const toast = useToast();
      const router = useRouter();

      const state = reactive({
        errorMessages: {
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        } as IErrorFields
      });

      const { passwords } = toRefs(props);
      const { errorMessages } = toRefs(state);

      const rules = computed(() => {
        return {
          currentPassword: { ...passwordValidation },
          newPassword: { ...passwordValidation },
          confirmPassword: {
            ...passwordValidation,
            sameAs: helpers.withMessage('Password doesn\'t matched', sameAs(passwords.value.newPassword))
          }
        };
      });

      const v$ = useVuelidate(rules, passwords);

      const hintOptions = computed<any>(() => [
        { message: 'At least one Capital letter', matched: !v$.value.newPassword.containsUppercase.$invalid },
        { message: '8 characters minimum', matched: !v$.value.newPassword.minLength.$invalid && v$.value.newPassword.$model },
        { message: 'At least one Number', matched: !v$.value.newPassword.containsNumber.$invalid }
      ]);

      function onCancel() {
        emit('cancel');
      }

      async function changePassword() {
        await authService.changePassword(passwords.value.currentPassword, passwords.value.newPassword)
          .then(() => {
            toast.success('Password has beed updated');
            emit('clear-password-fields');
            router.replace({ name: routesNames.userProfile });
          })
          .catch(({ code, message }) => {
            if (code === 'NotAuthorizedException') {
              errorMessages.value.currentPassword = 'Incorrect password';
            } else {
              toast.error(message);
            }
          });
      }

      async function onSubmit() {
        if (await v$.value.$validate()) { changePassword(); }
        else { handleSetErrors(v$.value.$errors, errorMessages.value); }
      }

      return {
        errorMessages,
        v$,
        hintOptions,

        onCancel,
        onSubmit,
        validateField: (name: string) => validateField(name, v$, errorMessages.value),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages.value),
      };
    }
  });
