
  import { defineComponent, onMounted, reactive, toRefs } from 'vue';
  import { useRouter } from 'vue-router';
  import { useToast } from "vue-toastification";

  import AppTabs from '@/components/stateless/AppTabs.vue';
  import UserDetails from './components/UserDetails.vue';
  import ChangePassword from './components/ChangePassword.vue';

  import { TIndexedObject, IPasswords, IProfile } from '@/types';
  import { vuex } from '@/store';
  import { profileService, authService } from '@/services';
  import { getChangedData } from '@/core/helper-functions';

  export default defineComponent({
    name: 'UserProfile',

    components: { AppTabs, UserDetails, ChangePassword },

    setup() {
      const router = useRouter();
      const toast = useToast();

      const state = reactive({
        activeTab: { value: 'details', label: 'My Details' } as TIndexedObject,
        details: {} as IProfile,
        originalDetails: {} as IProfile,
        passwords: {
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        } as IPasswords
      });

      const tabs: TIndexedObject[] = [
        { value: 'details', label: 'My Details' },
        { value: 'password', label: 'Update password' },
      ];

      const { activeTab, details, passwords, originalDetails } = toRefs(state);

      function onCancel() {
        router.back();
      }

      function setProfile(profile: IProfile) {
        const detailsData: IProfile = {
          ...profile,
          phoneNumber: profile.phoneNumber ? profile.phoneNumber : '',
          secondaryPhoneNumber: profile.secondaryPhoneNumber ? profile.secondaryPhoneNumber : '',
          zipCode: profile.zipCode ? profile.zipCode : ''
        };

        details.value = { ...detailsData };
        originalDetails.value = { ...detailsData };
      }

      function onReset() {
        passwords.value = {
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        };
        details.value = { ...originalDetails.value };
      }

      function onClearPasswords() {
        onReset();
        activeTab.value = { value: 'details', label: 'My Details' };
      }

      async function onSubmit(payload: IProfile) {
        vuex.setLoading(true);

        return profileService.updateProfile(getChangedData(originalDetails.value, payload) as IProfile)
          .then((res: IProfile) => {
            setProfile(res);
            toast.success('Profile has been updated');
            vuex.setLoading(false);

            return authService.getAuthenticatedUser(true);
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function fetchProfileData() {
        vuex.setLoading(true);

        return profileService.fetchProfile()
          .then((res: IProfile) => {
            setProfile(res);
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(async () => {
        await fetchProfileData();
      });

      return {
        activeTab,
        tabs,
        details,
        passwords,
        originalDetails,

        onSubmit,
        onCancel,
        onReset,
        onClearPasswords
      };
    }
  });
