import { required, helpers, minLength } from "@vuelidate/validators";
import { validateLength, containsUppercase, containsNumber  } from '@/core/helper-functions';

export const userDetailsVliadationRules = {
  firstName: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  lastName: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  zipCode: {
    validateZipCode: helpers.withMessage('Invalid ZipCode', validateLength(5))
  },
  phoneNumber: {
    validatePhoneNumber: helpers.withMessage('Invalid Phone number', validateLength(17))
  },
  secondaryPhoneNumber: {
    validatePhoneNumber: helpers.withMessage('Invalid Phone number', validateLength(17))
  }
};

export const passwordValidation = {
  minLength: helpers.withMessage('Password should contain minimum 8 characters', minLength(8)),
  containsUppercase: helpers.withMessage('Password should contain at least one capital letter', containsUppercase),
  containsNumber: helpers.withMessage('Password should contain at least one number', containsNumber),
  required: helpers.withMessage('This field cannot be empty', required)
};